/* eslint-disable no-unused-vars */
import request from '@/services/axios'
import config from '@/config/'
const baseUrl = config.baseUrl
export default {
  namespaced: true,
  state: {
    estates: {},

    user_estates: [],
    user_flats: [],
    manager_estates: [],

    current_estate: {},
    selected_flat: {},
    estate_tariffs: {},

    estate_block: {},

  },
  mutations: {
    SET_ESTATES(state, data) {
      state.estates = data
    },
    SET_MANAGER_ESTATES(state, data) {
      state.manager_estates = data
    },
    SET_CURRENT_ESTATE_BLOCK(state, data) {
      state.estate_block = data
    },
    SET_USER_ESTATES(state, data) {
      state.user_estates = data
    },

    SET_OBIDDER(state, data) {
      state.user_estates = data['user_estates']
      state.user_flats = data['user_flats']
      state.manager_estates = data['manager_estates']
    },

    SET_SELECTED_FLAT(state, data) {
      state.selected_flat = data
    },
    SET_CURRENT_ESTATE(state, data) {
      state.current_estate = data
      state.estate_tariffs = data.estate_tariffs
    },
    ADD_ESTATE_TARIFF(state, data) {
      state.estate_tariffs.push(data)
    },
    UPDATE_ESTATE_TARIFF(state, data, index) {
      state.estate_tariffs[index] = data
    },
    UPDATE_ESTATE_FEES(state, data, index) {
      state.current_estate.estate_fees.push(data)
    },
  },
  actions: {
    // BLOCK
    async getEstateBlock({ commit, rootState }, estateId) {
      const res = await request().get(baseUrl + 'blocks/' + estateId)
      console.log( res.data.data);
      commit('SET_CURRENT_ESTATE_BLOCK', res.data.data)
      return res.data.data
    },
    async genSetOptIn({ commit, rootState }, flat_id) {
      const res = await request().post(baseUrl + 'gensets/1/request/optin', {flat_id : flat_id})
      console.log( res.data.data);
      // commit('SET_CURRENT_ESTATE_BLOCK', res.data.data)
      return res.data
    },
    // ESTATES
    async getEstates({ commit }, query) {
      const url = new URL(baseUrl + 'estates')
      url.search = new URLSearchParams(query)
      const res = await request().get(url)
      if (query.user === true) {
        commit('SET_USER_ESTATES', res.data.data)
      } else if (query.manager === true) {
        commit('SET_MANAGER_ESTATES', res.data.data)
      } else {
        commit('SET_ESTATES', res.data.data)
      }
    },
    async getEstate({ commit, rootState }, estateId) {
      const res = await request().get(baseUrl + 'estates/' + estateId, {})
      commit('SET_CURRENT_ESTATE', res.data.data)
      return res.data.data
    },

    async setUpObidder({ commit, rootState }, estateId) {
      let userEstateFlats = rootState.auth.user_estate_flats
      let userManagedEstates = rootState.auth.manager_estates
      let userEstates = userEstateFlats
        .map((flat) => flat.estate)
        .concat(userManagedEstates)

      let data = []
      data['user_estates'] = userEstates
      data['user_flats'] = userEstateFlats
      data['manager_estates'] = userManagedEstates

      commit('SET_OBIDDER', data)
    },

    async storeEstate({ commit, rootState, dispatch }, payload) {
      const res = await request().post(baseUrl + 'estates', payload, {})
      await dispatch('getEstate', res.data.data.id)
      return res.data.data
    },

    async getEstateEnergyTransactions({ commit, rootState }, estateId) {
      const res = await request().get(
        baseUrl + `energy/transactions?estate_id= ${estateId}`,
        {},
      )
      return res.data.data
    },

    async getEnergyConsumptions({ commit, rootState }, estateId) {
      const res = await request().get(
        baseUrl + `estates/energy/consumption?estate_id=${estateId}`,
      )
      return res.data
    },

    async getEnergyTransactions({ commit, rootState }, estateId) {
      const res = await request().get(
        baseUrl + `estates/energy/transactions?estate_id=${estateId}`,
      )
      return res.data.data
    },

    async fetchEstateReport({ commit, rootState }, payload) {
      const url = new URL(baseUrl + `reports/estates/${payload.type}`)
      url.search = new URLSearchParams(payload.query)
      const res = await request().get(url, { responseType: 'arraybuffer' })
      return res
    },

    async getDeviceConsumptions({ commit, rootState }, payload) {
      const url = new URL(baseUrl + `device/${payload.imei}/consumptions`)
      url.search = new URLSearchParams(payload.query)
      const res = await request().get(url)
      return res.data.data
    },
    async getDeviceConsumptionReport({ commit, rootState }, payload) {
      const url = new URL(baseUrl + `reports/devices/consumption-graph`)
      url.search = new URLSearchParams(payload.query)
      const res = await request().get(url)
      return res.data.data ? res.data.data : res.data
    },

    async getDeviceTransactions({ commit, rootState }, payload) {
      const url = new URL(baseUrl + `device/${payload.imei}/transactions`)
      url.search = new URLSearchParams(payload.query)
      const res = await request().get(url)
      return res.data.data
    },

    async fetchDeviceReport({ commit, rootState }, payload) {
      const url = new URL(baseUrl + `reports/devices/${payload.type}`)
      url.search = new URLSearchParams(payload.query)
      const res = await request().get(url, {
        responseType: 'arraybuffer',
        'Access-Control-Allow-Origin': '*',
      })
      return res
    },

    async getFlatFeeInvoices({ commit, rootState }, payload) {
      const url = new URL(baseUrl + `estate/flats/${payload.flat_id}/invoices`)
      url.search = new URLSearchParams(payload.query)
      const res = await request().get(url)
      return res.data.data
    },

    async removeEstateFlatFee({}, payload){
      const url = new URL(baseUrl + `estate/flats/${payload.flat_id}/fees/${payload.id}`)
      return await request().delete(url)
    },

    async getEstateInvoices({ commit, rootState }, payload) {
      const url = new URL(baseUrl + `estates/${payload.estate_id}/invoices`)
      url.search = new URLSearchParams(payload.query)
      const res = await request().get(url)
      return res.data
    },

    // ESTATE FLAT
    async getEstateFlat({ commit, rootState }, estateFlatId) {
      const res = await request().get(
        baseUrl + 'estate/flats/' + estateFlatId,
        {},
      )
      commit('SET_SELECTED_FLAT', res.data.data)
      return res.data.data
    },

    // ESTATE FLATS
    async getEstateFlats({ commit, rootState, state }, query) {
      const url = new URL(baseUrl + 'estate/flats')
      url.search = new URLSearchParams(query)
      const res = await request().get(url)

      state.user_flats = res.data.data
      return res.data.data
    },

    async updateEstateFlat({ commit, dispatch }, flat) {
      const res = await request().patch(
        baseUrl + `estate/flats/${flat.id}`,
        flat,
        {},
      )
      dispatch('getEstateFlat', flat.id)
    },
    async storeEstateFlatTariff({ commit, dispatch }, tariff) {
      const res = await request().post(
        baseUrl + `estate/flats/tariffs`,
        tariff,
        {},
      )
      await dispatch('getEstateFlat', tariff.estate_flat_id)
    },
    async uploadEstateFlats({ commit, rootState, dispatch }, payload) {
      const res = await request().post(
        baseUrl + 'estate/flats/import',
        payload,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )
      // // console.log(payload)
      // await dispatch('getEstate', payload.estate_id)
    },

    async uploadEstateUsers({ commit, rootState, dispatch }, payload) {
      const res = await request().post(baseUrl + 'users/import', payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      // await dispatch('getEstate', payload.estate_id)
    },

    async joinEstate({ commit, rootState, dispatch }, payload) {
      const res = await request().post(baseUrl + 'estate/flats', payload, {})
      await dispatch('getEstateFlat', res.data.data.id)
      await dispatch('getEstate', payload.estate_id)
    },

    async shareEstateFlat({ commit, rootState, dispatch }, payload) {
      const res = await request().post(
        baseUrl + 'estate/flats/share',
        payload,
        {},
      )
      commit('SET_SELECTED_FLAT', res.data.data)
      return res.data.data
    },

    async buyToken({ commit, rootState, dispatch }, trxData) {
      const res = await request().post(
        baseUrl + `payments/deviceunit`,
        trxData,
        {},
      )
      return res.data.data
    },

    async buyDeviceUnit({ commit, rootState, dispatch }, trxData) {
      const res = await request().post(
        baseUrl + `payments/deviceunit`,
        trxData,
        {},
      )
      return res.data.data
    },

    async payEstateInvoice({ commit, rootState, dispatch }, trxData) {
      const res = await request().post(baseUrl + `payments`, trxData, {})
      return res.data.data
    },

    async sendTokenToUser({ commit, rootState }, transaction) {
      const res = await request().patch(
        baseUrl + `energy/transactions/${transaction.id}`,
        transaction,
        {},
      )
      return res.data.data
    },

    async updateDevice({ commit, rootState, dispatch }, device) {
      const res = await request().patch(
        baseUrl + `devices/${device.id}`,
        device,
        {},
      )
      dispatch('getEstateFlat', res.data.data.flat.id)
      return res.data.data
    },

    // Store Estate Energy Fee
    async storeEstateEnergyFee({ commit }, payload) {
      if (payload.id) {
        const res = await request().patch(
          baseUrl + `estate/fees/${payload.id}`,
          payload,
          {},
        )
        commit('UPDATE_ESTATE_FEES', res.data.data)
      } else {
        const res = await request().post(baseUrl + 'estate/fees', payload, {})
        commit('UPDATE_ESTATE_FEES', res.data.data)
      }
    },

    async getEstateFees({ commit }, estateId) {
      const res = await request().get(
        baseUrl + `estate/fees?estate_id=${estateId}`,
      )
      return res.data.data
    },

    // ESTATE TARIFF
    async storeEstateTariff({ commit, dispatch, rootState }, payload) {
      const res = await request().post(baseUrl + 'estate/tariffs', payload, {})
      await dispatch('getEstate', payload.estate_id)
    },
    async getEstateTariff({ commit, rootState }, tariff_id) {
      const res = await request().get(baseUrl + `estate/tariffs/${tariff_id}`)
      return res.data.data
    },
    async updateEstateTariff({ commit, rootState, dispatch }, payload) {
      // exlude the listed keys and return whats left as module
      let {
        index,
        estate_fees,
        estate_flats,
        id,
        created_at,
        updated_at,
        estate,
        ...data
      } = payload
      const res = await request().patch(baseUrl + `estate/tariffs/${id}`, data)
      commit('UPDATE_ESTATE_TARIFF', res.data.data, index)
      await dispatch('getEstate', payload.estate_id)
    },
    async deleteEstateTariff({ commit, dispatch, rootState }, payload) {
      const res = await request().delete(
        baseUrl + `estate/tariffs/${payload.id}`,
      )
      await dispatch('getEstate', payload.estate_id)
    },

    async updateEstatePricing({ commit, dispatch, rootState }, payload) {
      const res = await request().patch(baseUrl + 'estate/pricings', payload, {})
      await dispatch('getEstate', payload.estate_id)
      return res.data
    },


    // Estate Manager
    async storeEstateManager({ commit, dispatch, rootState }, payload) {
      const res = await request().post(
        baseUrl + `estate/${payload.estate_id}/managers`,
        payload,
        {},
      )
      await dispatch('getEstate', payload.estate_id)
    },
  },
  getters: {
    selected_flat: (state) => state.selected_flat,
    estate_tariffs: (state) => state.estate_tariffs,
    current_estate: (state) => state.current_estate,

    manager_estates: (state) => state.manager_estates,
    user_flats: (rootState) => state.user_flats,
    user_estates: (rootState) => state.user_estates,
  },
}
